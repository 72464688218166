import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Row } from "react-bootstrap";
import HeroImage from "../components/HeroImage/HeroImage";
import Layout from "../components/Layout";
import { recipes as data } from "../data/recipes";
import "./recipes.scss";
import large from "../images/recipes/recipes-l.jpg";
import medium from "../images/recipes/recipes-m.jpg";
import small from "../images/recipes/recipes-s.jpg";

const Recipes = () => {
  return (
    <Layout>
      <HeroImage
        children={
          <>
            <img
              className="container--row--large"
              src={large}
              loading="lazy"
              alt="The Fried Baloney Sandwich"
            />
            <img
              className="container--row--medium"
              src={medium}
              loading="lazy"
              alt="The Fried Baloney Sandwich"
            />
            <img
              className="container--row--small"
              src={small}
              loading="lazy"
              alt="The Fried Baloney Sandwich"
            />
            <div className="container--row--title">
              <StaticImage
                className="container--row--title--image"
                src="../images/recipes/recipes-title.png"
                loading="lazy"
                alt="Our Recipes"
              />
            </div>
          </>
        }
      />
      <Row md={3} xs={2} className="grid-wrapper recipeRow">
        {data.map((item, i) => (
          <div className="grid-wrapper--grid">
            <Link
              className="grid-wrapper--grid--link"
              to={"/recipes/" + item.path}
            >
              <img
                className="grid-wrapper--grid--link--image"
                loading="lazy"
                src={item.recipeImage}
                alt={item.recipeImageAlt}
              />
              <span className="grid-wrapper--grid--link--title">
                {item.recipeTitle}
              </span>
              {item.featured ? (
                <img
                  className="grid-wrapper--grid--link--featured"
                  loading="lazy"
                  src={item.featuredImage}
                  alt="Featured Recipe"
                />
              ) : null}
            </Link>
          </div>
        ))}
      </Row>
    </Layout>
  );
};

export default Recipes;
